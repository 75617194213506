import api from './index'
import { axios } from '@/utils/request'

// 获取服务包列表
export function servicePackList(parameter) {
    return axios({
        url: api.ServicePackList,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取服务包详情
export function servicePackInfo(parameter) {
    return axios({
        url: api.ServicePackInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取服务包下周期使用情况
export function packUsed(parameter) {
    return axios({
        url: api.PackUsed,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取服务包页面附加数据
export function servicePackAdditional() {
    return axios({
        url: api.ServicePackAdditional,
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加服务包
export function servicePackCreate(parameter) {
    return axios({
        url: api.ServicePackCreate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改服务包
export function ServicePackSave(parameter) {
    return axios({
        url: api.ServicePackSave,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除服务包
export function servicePackDelete(parameter) {
    return axios({
        url: api.ServicePackDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
